// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@include font-face(Roboto-Bold,    "fonts/Roboto/bold/Roboto-Bold");
@include font-face(Roboto-Medium,  "fonts/Roboto/medium/Roboto-Medium");
@include font-face(Roboto-Regular, "fonts/Roboto/regular/Roboto-Regular");


$ba-pc-red: #e30613;
$ba-pc-white: white;
$ba-pc-black: black;
$pc-light-grey: #FCFCFC;
$pc-border-grey: #E6E6E6;

#root {
  font-family: Roboto-Regular;

  input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #ccc;
  }

  button:focus {
    outline: none;
  }

  .alert-info {
    background-color: $ba-pc-red;
    border-color: $ba-pc-red;
    color: $ba-pc-white;
    border-radius: 0;

    strong {
      font-family: Roboto-Medium;
    }

    a {
      font-family: Roboto-Medium;
      color: $ba-pc-white;
      text-decoration: underline;
    }
  }

  .navbar {
    border-radius: 0;

    .container-fluid {
      .nav.navbar-nav {
        li.active {
          color: $ba-pc-white;
          background-color: $ba-pc-red;
        }
      }
    }
  }

  form {
    .form-group {
      label {
        font-family: Roboto-Medium;
      }
    }

    .form-control {
      border: 1px solid $pc-border-grey;
      background: $pc-light-grey;
      box-shadow: none;
      border-radius: 0;
    }

    .btn-primary {
      color: $ba-pc-white;
      background-color: $ba-pc-red;
      border-color: $ba-pc-red;
      opacity: 1;
      border-radius: 0;
      padding: .95rem 4rem;
      -webkit-transition:all .4s ease-in-out;
      transition:all .4s ease-in-out;

      &:hover {
        background-color: darken($ba-pc-red, 15%);
        border-color: darken($ba-pc-red, 15%);
      }
    }
  }

  .panel-info {
    border-radius: 0;
    -webkit-box-shadow: 0px 0px 20px 0 rgba(0, 46, 151, 0.2);
    box-shadow: 0px 0px 20px 0 rgba(0, 46, 151, 0.2);
    border: 0;

    .panel-heading {
      color: $ba-pc-white;
      background-color: $ba-pc-red;
      border-color: $ba-pc-red;
      border-radius: 0;

      .panel-title {
        font-size: 28px;
        font-family: Roboto-Medium;
      }
    }
  }

  #edit-profile-form {
    ul.list-inline {
      li {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
